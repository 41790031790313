@import "../../../data/styles.css";

.works {
	background-color: #ffffff;
	border-radius: 10px;
	margin-top: 50px;
	margin-left: 0;
	text-align: left;
	width: 100%;
}

.works2 {
	background-color: #ffffff;
	border-radius: 10px;
	margin-left: 0;
	text-align: left;
	width: 80%;
}

.work-header {
	margin-bottom: 10px;
}

.work-title {
	font-size: 1.5em;
	font-weight: bold;
	color: #333;
	margin-bottom: 5px;
}

.work-subtitle {
	font-size: 1.2em;
	color: #666;
	margin-bottom: 5px;
}

.work-duration {
	font-size: 1em;
	color: #999;
	margin-bottom: 15px;
}

.work-tech-stack {
	font-size: 1.1em;
	color: #333;
	margin-bottom: 15px;
	font-size: 18px;
}

.work-details {
	list-style-type: disc;
	padding-left: 20px;
	font-size: 1.1em;
}

.work-details li {
	margin-bottom: 10px;
	color: #555;
	font-size: 1.1em;
}
